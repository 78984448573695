.nav-item {
  .nav-link {
    display: flex;
    align-items: center;

    &.active {
      color: #fff;
      background: rgba(255, 255, 255, 0.23);
      opacity: 1;
    }
  }
}

.sidebar .nav a {
  transition: all 150ms ease-in;
}

.card-header {
  text-transform: uppercase;
}

.badge:empty {
  display: inline-block;
}

.popover {
  z-index: 1040;
}
.page-spinner {
  align-items: center !important;
  position: relative !important;
  top: 30% !important;
  left: 50% !important;
  height: 5rem;
  width: 5rem;
}

.footer-container {
  position: absolute !important;
  bottom: 0 !important;
}
